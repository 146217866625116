<template>
  <v-select
    label="text"
    :options="rubro_options"
    :reduce="rubro => mode === 'object' ? rubro : rubro.id"
    @search="debounceBuscarRubro"
    @input="setChange"
    v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
        class="vs__search"
        :class="classObject"
        v-bind="attributes"
        v-on="events"
      />
    </template>
  </v-select>
</template>

<script>
import Swal from "sweetalert2/src/sweetalert2.js";
import ServiceFactory from "../../services/ServiceFactory";
import {debounce, isEmpty} from 'lodash';
import VSelect from 'vue-select';
const RubroService = ServiceFactory.get("rubro");

export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      selected: this.value,
      rubro_options: [],
    };
  },
  created() {
    this.debounceBuscarRubro = debounce(this.buscarRubro, 300);
  },
  watch: {
      value : async function(value){

          if(this.mode === 'object'){
              if(isEmpty(value) || value === undefined || value === null){
                  this.selected = this.mode === 'object' ? {} : '';
                  return;
              }
          }else{
              if(value === '' || value === undefined || value === null){
                  this.selected = this.mode === 'object' ? {} : '';
                  return;
              }
          }

          let id = this.mode === 'object' ? value.id : value;

          if(isEmpty(this.rubro_options.find(x => x.id === id))){

              let response = await RubroService.getRubro(id);

              this.rubro_options.push({
                  text : `${response.data.codigo} - ${response.data.nombre}`,
                  id : response.data.id,
                  nombre : response.data.nombre,
                  codigo : response.data.codigo
              });
          }

          this.selected = value;
          this.$emit('input', value);
      }
  },
  methods: {
    async buscarRubro(search, loading) {
      loading(true);

      let filters = {
        filterByTipo: "C",
      };

      try {
        let response = await RubroService.search(search, filters);

        this.rubro_options = response.data.results;

        loading(false);
      } catch (error) {
        loading(false);
        Swal.fire({
          text:  "Ha Ocurrido un error, por favor intente nuevamente, si el problema persiste pongase en contacto con el administrador del sistema",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    setChange(value) {
      this.$emit("update-value", value);
      this.$emit("input", value);
    },
  },
  computed: {
    classObject: function () {

      if (this.validator === undefined || this.validator === null) {
        return "";
      }

      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    },
  },
};
</script>
<style scoped>
</style>
