<template>
  <v-select
      label="label"
      :options="cuentas"
      :reduce="cuenta => mode === 'object' ? cuenta : cuenta.id"
      @search="debounceSearch"
      @input="setChange"
      :class="classObject"
      v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
      />
    </template>
  </v-select>
</template>

<script>
import {debounce, isEmpty} from 'lodash';
import VSelect from 'vue-select';
import ServiceFactory from "../../services/ServiceFactory";

const PucService = ServiceFactory.get("puc");

export default {
  props: ["value", "mode", "filters", "validator"],
  components : {VSelect},
  data() {
    return {
      selected: this.value,
      cuentas: [],
    };
  },
  watch : {
    value : async function(value){

      if(this.mode === 'object'){
        if(isEmpty(value) || value === undefined || value === null){
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      }else{
        if(value === '' || value === undefined || value === null){
          this.selected = this.mode === 'object' ? {} : '';
          return;
        }
      }

      let id = this.mode === 'object' ? value.id : value;

      if(isEmpty(this.cuentas.find(x => x.id === id))){

        let response = await PucService.getAcountById(id);

        this.cuentas.push({
          label : `${response.data.codigo} - ${response.data.nombre}`,
          id : response.data.id,
          nombre : response.data.nombre,
          codigo : response.data.codigo,
          naturaleza : response.data.naturaleza,
          tipo : response.data.tipo
        });

      }

      this.selected = value;
      this.$emit('input', value);
    }
  },
  created() {
    this.debounceSearch = debounce(this.search, 300);
  },
  methods: {
    async search(search, loading) {
      if (search === "") {
        return;
      }
      loading(true);

      let response = null;

      if (this.filters !== undefined && this.filters !== null) {
        response = await PucService.getCuentas(search, null, this.filters);
      } else {
        response = await PucService.getCuentas(search, null);
      }

      this.cuentas = response.data;
      loading(false);
    },
    setChange(value) {
      this.$emit("update-value", value);
      this.$emit("input", value);
    }
  },
  computed: {
    classObject: function () {

      if (this.validator === undefined || this.validator === null) {
        return "";
      }

      return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
    }
  }
};
</script>
<style scoped>
</style>
