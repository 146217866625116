<template>
  <v-select
    label="label"
    :options="list"
    :reduce="tarifa => mode === 'object' ? tarifa : tarifa.id"
    @search="debounceSearch"
    @input="setChange"
    :class="classObject"
    v-model="selected"
  >
    <template #no-options="{ search }">
      No se encontraron resultados para <strong>{{ search }}</strong>
    </template>
    <template #search="{ attributes, events }">
      <input
        class="vs__search"
        :class="classObject"
        v-bind="attributes"
        v-on="events"
      />
    </template>
  </v-select>
</template>
<script>
import {isEmpty, debounce} from 'lodash';
import VSelect from 'vue-select';
import TarifaService from "../../services/tarifaService";

export default {
  props: ["value", "mode", "validator"],
  components : {VSelect},
  data() {
    return {
      selected : this.value,
      list: [],
    };
  },
  created() {
    this.debounceSearch = debounce(this.search, 300);
  },
  watch : {
      value : {
          handler : async function(value){

              if(this.mode === 'object'){
                  if(isEmpty(value) || value === undefined || value === null){
                      this.selected = this.mode === 'object' ? {} : '';
                      return;
                  }
              }else{
                  if(value === '' || value === undefined || value === null){
                      this.selected = this.mode === 'object' ? {} : '';
                      return;
                  }
              }

              let id = this.mode === 'object' ? value.id : value;

              if(isEmpty(this.list.find(x => x.id === id))){

                  let response = await TarifaService.show(id);

                  this.list.push({
                      label : `${response.data.nombre}`,
                      id : response.data.id
                  });
              }

              this.selected = value;
              this.$emit('input', value);
          },
          deep : true,
      }
  },
  methods: {
    async search(search, loading) {
      if(search === '')
      {
          return;
      }

      loading(true);
      let response = await TarifaService.search(search);
      this.list = response.data;
      loading(false);
    },
    setChange(value){
      this.$emit('update-value', value);
      this.$emit('input', value);
    }
  },
  computed : {
      classObject : function (){

          if(this.validator === undefined || this.validator === null){
              return '';
          }

          return this.validator.$error ? 'v-select-error is-invalid' : 'is-valid';
      }
  }
};
</script>
<style scoped>
</style>
